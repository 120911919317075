import { __assign, __awaiter, __generator } from "tslib";
import "suus-api";
import { customAttributeDefinitionsApi } from "store/api";
function parseUpdateErrors(error) {
    var details = error.details;
    return details.map(function (error) { return ({
        attributable: error.attributable,
        name: error.name,
        value: error.value,
        error: error.error,
    }); });
}
/**
 * API-CALLS
 */
var CustomAttributeDefinitionAPI = /** @class */ (function () {
    function CustomAttributeDefinitionAPI() {
    }
    CustomAttributeDefinitionAPI.fetch = fetchCustomAttributeDefinitions;
    CustomAttributeDefinitionAPI.create = createCustomAttributeDefinition;
    CustomAttributeDefinitionAPI.update = updateCustomAttributeDefinition;
    CustomAttributeDefinitionAPI.delete = deleteCustomAttributeDefinition;
    CustomAttributeDefinitionAPI.batchUpdate = batchUpdateCustomAttribute;
    return CustomAttributeDefinitionAPI;
}());
export { CustomAttributeDefinitionAPI };
function fetchCustomAttributeDefinitions(clientId, levelId) {
    return __awaiter(this, void 0, Promise, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, customAttributeDefinitionsApi.getCustomAttributeDefinitions(clientId === null || clientId === void 0 ? void 0 : clientId.toString(), levelId === null || levelId === void 0 ? void 0 : levelId.toString())];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function createCustomAttributeDefinition(customAttributeDefinition, clientId, levelId) {
    return __awaiter(this, void 0, Promise, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, customAttributeDefinitionsApi.createCustomAttributeDefinition(clientId === null || clientId === void 0 ? void 0 : clientId.toString(), levelId === null || levelId === void 0 ? void 0 : levelId.toString(), customAttributeDefinition)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
function updateCustomAttributeDefinition(customAttributeDefinition) {
    return __awaiter(this, void 0, Promise, function () {
        var payload, response, error_1, _error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    payload = __assign(__assign({}, customAttributeDefinition), { send_to_front: false, send_to_back: false });
                    return [4 /*yield*/, customAttributeDefinitionsApi.updateCustomAttributeDefinition(customAttributeDefinition.id.toString(), payload)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    _error = error_1;
                    if (_error.response.status === 424) {
                        return [2 /*return*/, parseUpdateErrors(_error.response.data)];
                    }
                    throw error_1;
                case 3: return [2 /*return*/];
            }
        });
    });
}
function deleteCustomAttributeDefinition(id) {
    return customAttributeDefinitionsApi
        .deleteCustomAttributeDefinition(id.toString())
        .then(function (response) { return response.data; });
}
function batchUpdateCustomAttribute(id, file) {
    return customAttributeDefinitionsApi.batchUpdateCustomAttributeValues(id.toString(), file);
}
