/**
 *  This is a simple Wrapper around the v-select component.
 *  It simplifies the interface and should keep the style decisions consistent.
 */
import { defineComponent } from "vue";
import FieldLabel from "components/UI/FieldLabel.vue";
export default defineComponent({
    name: "Select",
    components: { FieldLabel: FieldLabel },
    props: {
        label: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
        // Can be a Array of Strings or Objects
        items: {
            type: Array,
            required: true,
        },
        // (Ignored if items are strings)
        // Will be used it options are objects, to display the text
        itemText: {
            type: String,
            default: "text",
        },
        // (Ignored if items are strings)
        // Will be used it options are objects, to emit the value
        itemValue: {
            type: String,
            default: "value",
        },
        // The value of the select (Should be the selected !!!WHOLE!!! object or String)
        value: {
            type: null,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var emitItem = function (option) {
            emit("change", option);
        };
        return {
            emitItem: emitItem,
        };
    },
});
