import Vue from "vue";
import FieldLabel from "../../../components/UI/FieldLabel.vue";
export default Vue.extend({
    name: "InputField",
    components: { FieldLabel: FieldLabel },
    props: {
        placeholder: { type: String, default: "" },
        mandatory: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        label: { type: String, required: true },
        value: { type: String, default: null },
        rules: { type: Array, default: function () { return []; } },
    },
    data: function () {
        return {
            inputValue: this.value,
        };
    },
    watch: {
        value: function (val) {
            this.inputValue = val;
        },
        inputValue: function (val) {
            this.$emit("input", val);
        },
    },
});
