import { defineComponent, inject, provide, ref } from "vue";
import SuccessSnackbar from "app/ui-primitives/snackbars/SuccessSnackbar.vue";
import ErrorSnackbar from "app/ui-primitives/snackbars/ErrorSnackbar.vue";
var SNACKBAR_KEY = Symbol("snackbar");
export var useSnackbar = function () {
    var snackbarConcern = inject(SNACKBAR_KEY, null);
    if (!snackbarConcern) {
        throw new Error("useSnackbar must be used within a SnackbarProvider. Did you forget to add the provider at a root component?");
    }
    return snackbarConcern;
};
export default defineComponent({
    name: "SnackbarProvider",
    components: { ErrorSnackbar: ErrorSnackbar, SuccessSnackbar: SuccessSnackbar },
    setup: function () {
        var successSnackbar = ref(false);
        var successMessage = ref("");
        var errorSnackbar = ref(false);
        var errorMessage = ref("");
        var showSuccess = function (message) {
            successMessage.value = message;
            successSnackbar.value = true;
        };
        var showError = function (message) {
            errorMessage.value = message;
            errorSnackbar.value = true;
        };
        provide(SNACKBAR_KEY, { showSuccess: showSuccess, showError: showError });
        return { successSnackbar: successSnackbar, successMessage: successMessage, errorSnackbar: errorSnackbar, errorMessage: errorMessage };
    },
});
