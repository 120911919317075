import { mdiChevronRight } from "@mdi/js";
import Header from "../../UI/Header.vue";
import InputField from "../../../app/ui-primitives/forms/InputField.vue";
import ContentfulSelect from "components/UI/ContentfulSelect/index.vue";
import FieldLabel from "../../UI/FieldLabel.vue";
import TextBody from "../../UI/TextBody.vue";
import Subtitle from "../../UI/Subtitle.vue";
import Hyperlink from "../../UI/Hyperlink.vue";
import ChipSelectCombo from "../../../app/ui-primitives/forms/ChipSelectCombo.vue";
import { ref, computed, getCurrentInstance, } from "vue";
import { useAccessOptions } from "components/TopicForm/general-fields/use-access-options";
import Vue from "vue";
import "suus-api";
import GroupSelect from "features/common/forms/form-fields/GroupSelect.vue";
import "components/TopicForm/general-fields/group-access";
import useTranslate from "features/common/translate/concerns/useTranslate";
import { tagsApi } from "store/api";
export default Vue.extend({
    name: "TopicFormPanelGeneralFields",
    components: {
        GroupSelect: GroupSelect,
        Header: Header,
        InputField: InputField,
        ContentfulSelect: ContentfulSelect,
        ChipSelectCombo: ChipSelectCombo,
        FieldLabel: FieldLabel,
        Subtitle: Subtitle,
        TextBody: TextBody,
        Hyperlink: Hyperlink,
    },
    props: {
        inputTitle: { type: String, default: "" },
        inputGroup: { type: Object, default: null },
        inputLevelOrClient: {
            type: Object,
            default: null,
        },
        inputAccessVal: { type: String, default: null },
        inputTags: { type: Array, default: function () { return []; } },
        levelOrClientOptions: {
            type: Array,
            default: function () { return []; },
        },
        inputTitleValidation: { type: Array, default: function () { return []; } },
        inputGroupValidation: { type: Array, default: function () { return []; } },
        isEdit: { type: Boolean, default: false },
    },
    setup: function (props, context) {
        var root = getCurrentInstance().proxy;
        var isGroupPage = computed(function () { var _a, _b, _c; return !!((_c = (_b = (_a = root.$store.state.group) === null || _a === void 0 ? void 0 : _a.currentGroup) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.id); });
        var buildHandler = function (key) { return function (val) {
            return context.emit("update-field", { key: key, val: val });
        }; };
        // Tags Select Options
        var tagsOptions = ref([]);
        function composeListOfTagNames(tagsList) {
            return tagsList.map(function (tag) { return tag.name; });
        }
        var lastSelectedLevelId = ref(null);
        function updateTags(_a) {
            var selectedLevelId = _a.selectedLevelId, selectedClientId = _a.selectedClientId;
            if (selectedLevelId !== lastSelectedLevelId.value) {
                lastSelectedLevelId.value = selectedLevelId;
                tagsApi.getTags(selectedClientId, selectedLevelId).then(function (res) {
                    var tags = res.data;
                    tagsOptions.value = composeListOfTagNames(tags);
                });
            }
        }
        var handleAccessInput = buildHandler("inputAccessVal");
        var memberships = computed(function () {
            var _a, _b, _c;
            return (_c = (_b = (_a = root.$store.state.member) === null || _a === void 0 ? void 0 : _a.currentMember) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.group_memberships;
        });
        var _selectedGroup = computed(function () { return props.inputGroup; });
        var accessOptions = useAccessOptions(_selectedGroup, memberships, computed(function () { return props.inputAccessVal; }), function (access) {
            handleAccessInput(access);
        });
        var t$ = useTranslate("components.topic_form").t$;
        return {
            handleTitleInput: buildHandler("inputTitle"),
            handleTagsInput: buildHandler("inputTags"),
            handleGroupInput: buildHandler("inputGroup"),
            handleLevelOrClientInput: buildHandler("inputLevelOrClient"),
            handleAccessInput: handleAccessInput,
            accessOptions: accessOptions,
            tagsOptions: tagsOptions,
            isGroupPage: isGroupPage,
            updateTags: updateTags,
            t$: t$,
        };
    },
    data: function () {
        return {
            chevron: mdiChevronRight,
        };
    },
});
