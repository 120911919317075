import { defineComponent } from "vue";
import { mdiCheckCircle, mdiClose } from "@mdi/js";
import { successBackgroundTint, successColor } from "constants/theme";
import TextBody from "components/UI/TextBody.vue";
import { useThemeColors } from "features/common/theme/useTheme";
export default defineComponent({
    name: "SuccessSnackbar",
    components: { TextBody: TextBody },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var emitInput = function (value) {
            emit("input", value);
        };
        var themeColors = useThemeColors();
        return {
            emitInput: emitInput,
            mdiCheckCircle: mdiCheckCircle,
            successBackgroundTint: successBackgroundTint,
            themeColors: themeColors,
            mdiClose: mdiClose,
            successColor: successColor,
        };
    },
});
