import { __spreadArray } from "tslib";
import { computed, defineComponent, ref } from "vue";
import { mdiDelete, mdiPlus } from "@mdi/js";
export default defineComponent({
    name: "CustomAttributesSelectValues",
    props: {
        values: {
            type: Array,
            required: false,
        },
    },
    setup: function (props, _a) {
        var _b;
        var emit = _a.emit;
        var selectValues = ref((_b = props.values) !== null && _b !== void 0 ? _b : []);
        var emitSelectValues = function () {
            emit("change", selectValues.value);
        };
        var removeItem = function (value) {
            selectValues.value = selectValues.value.filter(function (v) { return v !== value; });
            emitSelectValues();
        };
        var addItem = function () {
            selectValues.value = __spreadArray(__spreadArray([], selectValues.value, true), [textFieldVale.value], false);
            textFieldVale.value = "";
            emitSelectValues();
        };
        var textFieldVale = ref("");
        var disableAddButton = computed(function () { return textFieldVale.value === ""; });
        return {
            selectValues: selectValues,
            emitSelectValues: emitSelectValues,
            mdiDelete: mdiDelete,
            mdiPlus: mdiPlus,
            removeItem: removeItem,
            addItem: addItem,
            textFieldVale: textFieldVale,
            disableAddButton: disableAddButton,
        };
    },
});
