<template lang="pug">
portal.portal(selector=".topic-event-form-portal")
  ConfirmationDialogProvider
    .d-flex.flex-column.flex-sm-row.justify-center.justify-sm-end
      TopicForm.pa-1.px-sm-1.py-sm-0(
        v-if="canCreateTopicOnClient",
        :isEdit="false"
      )
      EventSheetButton.pa-1.px-sm-1.py-sm-0(v-if="canCreateEventOnClient")
        template(v-slot:activator="{openSheet}")
          div(:style="{ width: '100%' }")
            Button(
              :btnLabel="btnLabel",
              :block="isXsScreen",
              @click="openSheet"
            )
</template>

<script>
import TopicForm from "../TopicForm/index.vue"
import EventForm from "../EventForm/EventForm.vue"
import Button from "../UI/Button.vue"

import { createNamespacedHelpers } from "vuex"
import EventSheetButton from "components/Event/EventSheetButton.vue"
import ConfirmationDialogProvider from "features/common/confirmation-dialog/ConfirmationDialogProvider.vue"
const { mapGetters: mapMemberGetters, mapActions: mapMemberActions } =
  createNamespacedHelpers("member")
const { mapActions: mapGroupActions } = createNamespacedHelpers("group")
export default {
  name: "PortalTopicAndEventForm",
  components: {
    ConfirmationDialogProvider,
    EventSheetButton,
    TopicForm,
    EventForm,
    Button,
  },
  computed: {
    currentGroupIdFromRoute() {
      return this.$route.params.group_id || null
    },
    btnLabel() {
      return this.$t("components.event_form.new_event")
    },
    isXsScreen() {
      return this.$vuetify.breakpoint.name === "xs"
    },
    ...mapMemberGetters(["canCreateTopicOnClient", "canCreateEventOnClient"]),
  },
  methods: {
    ...mapMemberActions(["getCurrentMember"]),
    ...mapGroupActions(["getGroup"]),
  },
  async created() {
    await this.getCurrentMember()
    if (this.currentGroupIdFromRoute) {
      await this.getGroup(this.currentGroupIdFromRoute)
    }
  },
}
</script>
