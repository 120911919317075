import { defineComponent } from "vue";
import { mdiClose, mdiCloseCircle } from "@mdi/js";
import { errorBackgroundTint } from "constants/theme";
import TextBody from "components/UI/TextBody.vue";
import { useThemeColors } from "features/common/theme/useTheme";
export default defineComponent({
    name: "ErrorSnackbar",
    components: { TextBody: TextBody },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var emitInput = function (value) {
            emit("input", value);
        };
        var themeColors = useThemeColors();
        return {
            emitInput: emitInput,
            mdiCloseCircle: mdiCloseCircle,
            errorBackgroundTint: errorBackgroundTint,
            themeColors: themeColors,
            mdiClose: mdiClose,
        };
    },
});
