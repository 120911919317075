import { computed, defineComponent, inject, } from "vue";
import SuusTextField from "app/ui-primitives/forms/SuusTextField.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import trimIfString from "features/common/util/trimIfString";
import "features/events/form/event-form.service";
import "features/events/form/event-form.types";
import "components/EventForm/EventForm.vue";
import "features/common/forms/form.service";
export default defineComponent({
    name: "TitleTextField",
    components: { SuusTextField: SuusTextField },
    props: {
        panelIdentifier: {
            type: String,
            required: true,
        },
    },
    setup: function (props) {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        eventFormService.registerProperty({
            name: "title",
        });
        var validationRule = {
            field: "title",
            message: t$(".validate_title").toString(),
            selector: "event-form__title",
            panelIdentifier: props.panelIdentifier,
            action: function (v) { return !!v && !!v.length; },
        };
        eventFormService.registerRule(validationRule);
        var title = computed({
            get: function () {
                return eventFormService.getFormValue("title").value || "";
            },
            set: function (value) {
                eventFormService.setFormValue("title", trimIfString(value));
                eventFormService.validate();
            },
        });
        return {
            t$: t$,
            title: title,
        };
    },
});
