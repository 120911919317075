import Vue, { ref, watch } from "vue";
import { mdiCloseCircle } from "@mdi/js";
import FieldLabel from "../../../components/UI/FieldLabel.vue";
export default Vue.extend({
    name: "ChipSelectCombo",
    components: { FieldLabel: FieldLabel },
    props: {
        options: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        value: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        label: { type: String, required: true },
        noDataHTMLText: {
            type: String,
            default: "Keine Ergebnissse für <strong>${this.search}</strong>. Klicke auf <kbd>enter</kbd> um eine neue Option zu erstellen.",
        },
        mandatory: { type: Boolean, default: false },
        error: {
            type: String,
            default: undefined,
        },
    },
    data: function () {
        return {
            closeIcon: mdiCloseCircle,
            menu: false,
            model: this.value,
            search: null,
        };
    },
    computed: {
        noDataFoundForSearch: function () {
            return "".concat(this.noDataHTMLText);
        },
        rules: function () {
            return this.mandatory
                ? [
                    function (v) {
                        return !!v.length ||
                            "Bitte geben Sie mindestens einen Wert für die Auswahl an.";
                    },
                ]
                : [];
        },
    },
    watch: {
        value: function (val) {
            this.model = val;
        },
        model: function (val, prev) {
            if (val.length === prev.length)
                return;
            this.model = val;
            this.$emit("input", val);
        },
        search: function (val, prev) {
            if (val === prev)
                return;
            this.$emit("search", val);
        },
    },
    setup: function (props) {
        var errors = ref([]);
        if (props.error) {
            errors.value = [props.error];
        }
        watch(function () { return props.error; }, function (value) {
            errors.value = value ? [value] : [];
        });
        return {
            errors: errors,
        };
    },
});
