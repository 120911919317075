import { defineComponent } from "vue";
import TextBody from "components/UI/TextBody.vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import Header4 from "app/ui-primitives/header/Header4.vue";
export default defineComponent({
    name: "ErrorAlert",
    components: { Header4: Header4, Header2: Header2, TextBody: TextBody },
    props: {
        title: {
            type: String,
            default: "Fehler",
        },
        message: {
            type: String,
            default: "Es ist ein Fehler aufgetreten. Versuche es später erneut.",
        },
        maxWidth: {
            type: Number,
            default: null,
        },
    },
});
