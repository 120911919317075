import { __assign, __awaiter, __generator } from "tslib";
import { defineComponent, getCurrentInstance, inject, provide } from "vue";
import { EditorContent } from "@tiptap/vue-2";
import "@tiptap/core";
import { ref } from "vue";
import MenuBar from "./menu/MenuBar.vue";
import "components/Wysiwyg/menu/MenuItem.vue";
import "features/wysiwyg/addon/wysiwyg-addon.types";
import { ConfiguredSuusMentionExtension, } from "components/Wysiwyg/extensions/suus-mention/suus-mention.config";
import CoreWysiwyg from "components/Wysiwyg/CoreWysiwyg.vue";
import AttachmentUploader from "features/attachments/AttachmentUploader.vue";
import { Link } from "@tiptap/extension-link";
import AttachmentItemExtension from "components/Wysiwyg/extensions/attachment/attachment-item/attachment-item.extension";
import FileHandler from "@tiptap-pro/extension-file-handler";
import { mdiAttachment } from "@mdi/js";
import "features/attachments/attachment.types";
import { isFileSupported } from "app/modules/core/supported-image-formats";
export default defineComponent({
    components: {
        AttachmentUploader: AttachmentUploader,
        CoreWysiwyg: CoreWysiwyg,
        EditorContent: EditorContent,
        MenuBar: MenuBar,
    },
    props: {
        value: { type: String, default: "" },
        showControls: { type: Boolean, default: true },
        placeholder: { type: String, default: "" },
        inline: { type: Boolean, default: false },
        getMentionables: {
            type: Function,
            default: function (_) { return []; },
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var additionalMenuItems = [];
        provide("retryUpload", function (frontendId) {
            return emit("retryUpload", frontendId);
        });
        provide("cancelUpload", function (frontendId) {
            return emit("cancelUpload", frontendId);
        });
        var uploadFile = inject("uploadFile");
        var registerAddon = function (wysiwygAddon) {
            additionalMenuItems.push(wysiwygAddon.addonMenuItem);
        };
        var editor = ref(null);
        var updateNodeBasedOnProp = function (editor, propName, propValue, newAttributes) {
            // Clone the current state to ensure we're always working with the latest during iteration
            var updatedState = editor.state;
            updatedState.doc.descendants(function (node, pos) {
                if (node.attrs[propName] === propValue) {
                    var tr = updatedState.tr; // Start a new transaction from the updated state
                    tr.setNodeMarkup(pos, null, __assign(__assign({}, node.attrs), newAttributes)); // Prepare to update node attributes
                    // Check if the transaction can be applied
                    if (tr.docChanged) {
                        editor.view.updateState(editor.view.state.apply(tr)); // Apply the transaction
                        updatedState = editor.view.state; // Update the state reference for the next iteration
                    }
                }
            });
            emit("input", editor.getHTML());
        };
        var handleFileDrop = function (editor, files, pos) {
            var _a, _b;
            var tr = editor.state.tr; // Get the current transaction
            var _loop_1 = function (file) {
                // skip folders
                if (file.type === "") {
                    return "continue";
                }
                // Do not use await here to execute uploads of multiple files in parallel
                var attachmentMediaUpload = uploadFile(file);
                var editorContent = editor.state.doc.content;
                var isContentEmpty = editorContent.size === 0; // Check if the editor is empty
                var isContentOnlyEmptyParagraph = editorContent.toJSON().length === 1 && // ATTENTION: I dont know why, but editorContent.toJSON().length is 1, editorContent.size would be 2.
                    ((_a = editorContent.firstChild) === null || _a === void 0 ? void 0 : _a.type.name) === "paragraph" &&
                    ((_b = editorContent.firstChild) === null || _b === void 0 ? void 0 : _b.content.size) === 0; // Check if the editor contains only a placeholder
                if ((pos === 1 || pos === 2) &&
                    (isContentEmpty || isContentOnlyEmptyParagraph)) {
                    console.warn("Setting position to 0 because editor is empty.");
                    pos = 0; // Set position to the beginning if the editor is empty & the position is at the beginning
                }
                if (pos > tr.doc.content.size) {
                    pos = tr.doc.content.size; // Adjust position to the maximum possible if out of bounds
                }
                var isImage = file.type.startsWith("image/");
                var isSupportedImage = isFileSupported(file);
                tr.insert(pos, editor.schema.nodes.attachmentItem.create(__assign({ "data-frontend-id": attachmentMediaUpload.value.frontendId, "data-file-name": file.name, "data-stored-file": "" }, (isImage && isSupportedImage
                    ? {
                        "data-image-mode": "full-width",
                        "data-image-size": "",
                    }
                    : {}))));
                pos++; // Update position for next insertion
                attachmentMediaUpload.value.storedFile.then(function (storedFile) {
                    updateNodeBasedOnProp(editor, "data-frontend-id", attachmentMediaUpload.value.frontendId, {
                        "data-stored-file": storedFile,
                    });
                }, function (err) {
                    console.error(err);
                });
            };
            for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
                var file = files_1[_i];
                _loop_1(file);
            }
            // Apply the transaction
            if (tr.steps.length > 0) {
                editor.view.dispatch(tr);
            }
        };
        var onPaste = function (editor, files, htmlContent) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, handleFileDrop(editor, files, editor.state.selection.from)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        additionalMenuItems.push({
            icon: mdiAttachment,
            title: "Datei anhängen",
            action: function () { return __awaiter(_this, void 0, void 0, function () {
                var input;
                var _this = this;
                return __generator(this, function (_a) {
                    input = document.createElement("input");
                    input.type = "file";
                    input.multiple = true; // Allow multiple file selection
                    input.onchange = function (e) { return __awaiter(_this, void 0, void 0, function () {
                        var target, files;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    target = e.target;
                                    files = target.files ? Array.from(target.files) : [];
                                    return [4 /*yield*/, handleFileDrop(editor.value, files, 0)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    input.click();
                    return [2 /*return*/];
                });
            }); },
        });
        var extensions = [
            FileHandler.configure({ onDrop: handleFileDrop, onPaste: onPaste }),
            AttachmentItemExtension.configure({ HTMLAttributes: {} }),
            ConfiguredSuusMentionExtension(props.getMentionables, getCurrentInstance().proxy),
            Link, // a-Tags from mention has higher precedence!
        ];
        var onEditorReady = function (_editor) {
            editor.value = _editor;
        };
        var onInput = function (val) {
            emit("input", val);
        };
        return {
            registerAddon: registerAddon,
            additionalMenuItems: additionalMenuItems,
            editor: editor,
            extensions: extensions,
            onEditorReady: onEditorReady,
            onInput: onInput,
        };
    },
});
