import { computed, defineComponent, onMounted, ref } from "vue";
import "suus-api";
import Button from "components/UI/Button.vue";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import Sortable from "sortablejs";
import CustomAttributesListItem from "app/modules/custom-attributes/CustomAttributesListItem.vue";
import "app/modules/custom-attributes/types";
export default defineComponent({
    name: "CustomAttributesList",
    components: {
        CustomAttributesListItem: CustomAttributesListItem,
        Header2: Header2,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
        Button: Button,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        customAttributes: {
            type: Array,
            required: true,
        },
        update: {
            type: Function,
            required: true,
        },
        batchUpdate: {
            type: Function,
        },
        listId: {
            type: String,
            required: true,
        },
        // Is used to group multiple lists together, so Drag&Drop works across lists.
        listGroup: {
            type: String,
            default: undefined,
            required: false,
        },
    },
    // emits: {reorder: ReorderEvent},
    setup: function (props, _a) {
        var emit = _a.emit;
        /**
         *  REORDERING
         */
        var listContainer = ref(null);
        onMounted(function () {
            new Sortable(listContainer.value.$el, {
                group: props.listGroup,
                sort: true,
                handle: ".custom-attr-handle",
                chosenClass: "chosen",
                filter: ".empty-state",
                onEnd: function (event) {
                    emit("reorder", {
                        from: event.from.id,
                        to: event.to.id,
                        oldIndex: event.oldIndex,
                        newIndex: event.newIndex,
                    });
                },
            });
            // Observe children changes to show/hide placeholder
            var observer = new MutationObserver(checkChildrenForPlaceholder);
            var config = { childList: true };
            observer.observe(listContainer.value.$el, config);
        });
        // REORDERING PLACEHOLDER LOGIC (SortablesJS does not support placeholders seemingly)
        // [hidePlaceholder] needs to be tracked independently, because there can be a list child without custom attributes having an element.
        var hidePlaceholder = ref(false);
        var isPlaceholderVisible = computed(function () { return props.customAttributes.length === 0 && !hidePlaceholder.value; });
        var updatePlaceholderVisibility = function (children) {
            if (children.length >= 2) {
                hidePlaceholder.value = true; // Hide placeholder because another element is present
            }
            else if (children.length === 0) {
                hidePlaceholder.value = false; // Show placeholder because no element is present
            }
        };
        var checkChildrenForPlaceholder = function (mutationList) {
            mutationList.forEach(function (mutation) {
                if (mutation.type === "childList") {
                    updatePlaceholderVisibility(mutation.target.children);
                }
            });
        };
        var additionalContainerClasses = computed(function () {
            return {
                "dropzone-visible": props.customAttributes.length === 0 && !props.loading,
            };
        });
        var deleteCustomAttribute = function (id) {
            emit("delete", id);
        };
        return {
            listContainer: listContainer,
            additionalContainerClasses: additionalContainerClasses,
            isPlaceholderVisible: isPlaceholderVisible,
            deleteCustomAttribute: deleteCustomAttribute,
        };
    },
});
