var THEME = (function (scale) {
    if (scale === void 0) { scale = 1; }
    var fontFamily = "system-ui !important";
    var rootFontSize = 16 * scale;
    var fontWeightLight = 300;
    var fontWeightRegular = 400;
    var fontWeightMedium = 700;
    var fontWeightBold = 900;
    return {
        typography: {
            rootFontSize: rootFontSize,
            fontFamily: fontFamily,
            fontWeightLight: fontWeightLight,
            fontWeightRegular: fontWeightRegular,
            fontWeightMedium: fontWeightMedium,
            fontWeightBold: fontWeightBold,
            button: {
                fontFamily: fontFamily,
                fontWeight: fontWeightBold,
                letterSpacing: "0px",
                fontSize: "".concat(rootFontSize * 0.9, "px"),
            },
            inputMessage: {
                fontFamily: fontFamily,
                fontSize: "".concat(rootFontSize * 0.8, "px"),
            },
            hyperlink: {
                fontFamily: fontFamily,
                fontSize: "".concat(rootFontSize * 0.9, "px"),
                lineHeight: "".concat(rootFontSize * 0.9 * 1.5, "px"),
                fontWeight: fontWeightRegular,
                textDecoration: "underline",
            },
            subtitle: {
                fontFamily: fontFamily,
                fontSize: "".concat(rootFontSize * 0.9, "px"),
                lineHeight: "".concat(rootFontSize * 0.9 * 1.5, "px"),
                fontWeight: fontWeightRegular,
            },
            text: {
                fontFamily: fontFamily,
                fontSize: "".concat(rootFontSize * 0.9, "px"),
                lineHeight: "".concat(rootFontSize * 1.5, "px"),
            },
        },
        colors: {
            light: {
                primary: "#307EF7",
                primaryHover: "#518EFC",
                primaryLight: "#B9D0FF",
                highlight: "#22D563",
                textColor: "#424242",
                lightGrey: "#f5f5f5",
                mediumGrey: "#e5e5e5",
                darkGrey: "#939393",
                error: "#B00020",
                surface: "#F8F8F8",
                white: "#FFFFFF",
            },
        },
    };
})();
export default THEME;
// TODO: Check if these colors can be added to the theme palette without breaking the Vuetify Theme
export var errorBackgroundTint = "#F5E9EB";
export var successBackgroundTint = "#E9F5ED";
export var successColor = "#3EB068";
