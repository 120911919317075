import { __assign, __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import { CustomAttributeDefinitionAPI } from "app/modules/custom-attributes/api";
import "suus-api";
import CustomAttributesManagement from "app/modules/custom-attributes/CustomAttributesManagement.vue";
import ConfirmationDialogProvider from "features/common/confirmation-dialog/ConfirmationDialogProvider.vue";
import "app/modules/custom-attributes/types";
export default defineComponent({
    name: "CustomAttributesManagementPortal",
    components: {
        ConfirmationDialogProvider: ConfirmationDialogProvider,
        CustomAttributesManagement: CustomAttributesManagement,
        Header2: Header2,
    },
    setup: function () {
        var _this = this;
        var render = ref(false);
        var clientId = ref(null);
        var levelId = ref(null);
        onMounted(function () {
            var _a, _b;
            var mountingPoint = document.querySelector("#custom-attributes-management");
            if (mountingPoint) {
                //mountingPoint.classList.add("v-application") // add vuetify styles
                // read the data attribute and parse it as json
                var dataRaw = mountingPoint.getAttribute("data");
                var data = dataRaw ? JSON.parse(dataRaw) : {};
                clientId.value = (_a = data.clientId) !== null && _a !== void 0 ? _a : null;
                levelId.value = (_b = data.levelId) !== null && _b !== void 0 ? _b : null;
                render.value = true;
            }
        });
        var cachedCustomAttributes = ref([]);
        var setCachedCustomAttributes = function (customAttributes) {
            cachedCustomAttributes.value = customAttributes;
        };
        var fetchFunction = function () {
            return CustomAttributeDefinitionAPI.fetch(clientId.value, levelId.value);
        };
        var createFunction = function (data) {
            return CustomAttributeDefinitionAPI.create(data, clientId.value, levelId.value);
        };
        var updateFunction = CustomAttributeDefinitionAPI.update;
        var deleteFunction = CustomAttributeDefinitionAPI.delete;
        var batchUpdateFunction = CustomAttributeDefinitionAPI.batchUpdate;
        var reorderFunction = function (customAttributeId, newIndex, changedAttributableType) { return __awaiter(_this, void 0, void 0, function () {
            var customAttribute;
            return __generator(this, function (_a) {
                customAttribute = cachedCustomAttributes.value.find(function (ca) { return ca.id === customAttributeId; });
                if (!customAttribute) {
                    throw new Error("Das Feld zum Verschieben konnte nicht gefunden werden.");
                }
                return [2 /*return*/, CustomAttributeDefinitionAPI.update(__assign(__assign(__assign({}, customAttribute), { index: newIndex }), (changedAttributableType
                        ? { attributable_type: changedAttributableType }
                        : {}))).then(function () {
                        //pass
                    })];
            });
        }); };
        return {
            fetchFunction: fetchFunction,
            createFunction: createFunction,
            updateFunction: updateFunction,
            deleteFunction: deleteFunction,
            batchUpdateFunction: batchUpdateFunction,
            reorderFunction: reorderFunction,
            setCachedCustomAttributes: setCachedCustomAttributes,
            render: render,
        };
    },
});
