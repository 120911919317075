import { __assign } from "tslib";
import "features/changelog/changelog.types";
/*
The description should answer the following questions very briefly:
 - What new can I see now?
 - Where/how do I see it?
 The tags should be aligned after our core features:
  - App
  - Push-Nachrichten
  - Organisation
  - Verwaltung
  - Verband
  - Fotos
  - Digest
  - Export
  - Dateiablage
  - Kommunikation
  - Profil
  - Suche
  - Feedback
*/
/*
 * REMEMBER TO UPDATE MemberDigest::LAST_CHANGELOG_DATE
 * */
export var changelogItems = [
    {
        title: "🎙️ Mitgründer im Korpotalk-Podcast",
        date: "12.09.2024",
        image: "/images/changelog/korpotalk.png",
        description: "Unser Mitgründer Prettl II Borussiae Tübingen war kürzlich im Podcast für Verbindungsstudenten zu Gast. Er spricht dort auch über unsere Software und die Vision dahinter. Klicken Sie auf “Mehr erfahren” und hören Sie mal rein!",
        link: "https://korpo-talk.podigee.io/38-new-episode",
    },
    {
        title: "Sie haben es in Ihrer Hand!",
        date: "30.09.2024",
        image: "/images/changelog/survey.jpg",
        description: 'Machen Sie bei unserer Umfrage mit und verbessern Sie damit Ihr Intranet Ihrer Verbindung - inklusive doppelter Chance auf eine Kiste Bier. Klicken Sie auf "Mehr erfahren" und nehmen Sie bis zum 4. Oktober teil.',
        tags: ["Feedback"],
        link: "https://survey.lamapoll.de/Verbindungs-Intranet-Umfrage-2024",
    },
    {
        title: "Bilder im Forum und Digest",
        date: "08.07.2024",
        image: "/images/changelog/images_in_editor.png",
        description: "Der Texteditor wurde verbessert und erlaubt nun das Einbinden von Bildern und Dateien direkt in den Text. Im Digest wird der Text nun so wie im Forum angzeigt, mit Formatierung und Bildern.",
        tags: ["Kommunikation", "Fotos", "Digest"],
        link: "https://suus.atlassian.net/wiki/x/BYCJdw",
    },
    {
        title: "Neueste Veranstaltungsfotos auf der Startseite",
        date: "25.04.2024",
        image: "/images/changelog/dashboard_galleries.png",
        description: "Wenn du eine Fotogalerie zu einer Veranstaltung hochlädst, erscheint diese nun auf der Startseite gut sichtbar für alle Mitglieder. Während im Digest nur auf neue Fotos in deinen Gruppen hingewiesen wird, erscheinen hier Bilder aus allen Gruppen, auf die du Zugriff hast.",
        tags: ["Fotos"],
    },
    {
        image: "/images/changelog/suus_abend2.png",
        title: "SUUS Abend März 2024",
        date: "28.03.2024",
        description: "Wir danken allen Teilnehmern des SUUS-Abends für die rege Beteiligung und das positives Feedback. Die Atmosphäre war angenehm, der Austausch bereichernd. Ihr Vertrauen und Ihre Unterstützung motivieren uns, zukünftig weitere SUUS-Abende zu planen. Für Interessierte, die den Abend verpasst haben, ist ein Videomitschnitt  unter dem Link in unserem Hilfebereich verfügbar.",
        tags: ["Rückblick", "Ausblick", "Feedback"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/2032467976/SUUS-Abend+M+rz+2024",
    },
    {
        image: "/images/changelog/attachments_in_digest.png",
        title: "Dateianhänge im Digest",
        date: "27.01.2024",
        description: "Dateianhänge erscheinen nun auch im Digest, sodass man sie direkt herunterladen kann. Bei dringenden Nachrichten sind die Dateien als E-Mail-Anhang mit angehängt, falls die Anhänge unter 10 MB bleiben.",
        link: "https://suus.atlassian.net/wiki/x/BYCJdw",
        tags: ["Digest", "Kommunikation", "Dateien"],
    },
    {
        image: "/images/changelog/highlights_v1.png",
        title: "Veranstaltungs-Highlights",
        date: "28.11.2023",
        description: 'Aus all den geteilten Fotos einer Veranstaltung können die Organisatoren nun eine Auswahl der schönsten Fotos als "Highlights" zusammenstellen.',
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1959067659/Wie+erstelle+und+organisiere+ich+Highlights+zu+einer+Veranstaltung+aus+hochgeladenen+Bildern+Medien",
        tags: ["Veranstaltungen", "Organisation", "Fotos"],
    },
    {
        image: "/images/changelog/push_notification.png",
        title: "Push-Nachrichten",
        date: "14.11.2023",
        description: "Auf Deinem Handy oder Tablet kannst Du nun Push-Nachrichten aktivieren, um über neue Veranstaltung, neue Bildergalerien und andere Neuigkeiten informiert zu werden. Erfordert iOS 16.4+ oder Android Chrome.",
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1937637387/Wie+abonniere+ich+Push-Benachrichtigungen+auf+meinem+Smartphone+oder+Computer",
        tags: ["App", "Push-Nachrichten"],
    },
    {
        image: "/images/changelog/open_invites_dashboard.png",
        title: "Verbesserte Übersicht bei meinen Veranstaltungen",
        date: "01.11.2023",
        description: "Sagt man für eine Veranstaltung ab, verschwindet sie nun aus dem persönlichen Kalender. Zudem sieht man auf der Startseite, wenn man sich zu Veranstaltungen angemeldet hat oder noch unbeantwortete Einladungen hat.",
        tags: ["Veranstaltungen"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1573781505/Wie+sage+ich+zu+einer+Veranstaltung+ab",
    },
    {
        image: "/images/changelog/digest_event_media.png",
        title: "Fotos im Digest",
        date: "10.11.2023",
        description: "Teilt jemand neue Fotos in einer Veranstaltung, erscheinen diese nun im Digest und als Push-Benachrichtigung bei allen Teilnehmern und Gruppenmitgliedern.",
        tags: ["Veranstaltungen", "Fotos", "Digest"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/125763603/Die+E-Mail-Zusammenfassung+bzw.+der+Digest",
    },
    {
        image: "/images/changelog/galleries.gif",
        title: "Veranstaltungsgalerien",
        date: "10.11.2023",
        description: "Zu Veranstaltungen kann jedes Mitglied nun seine Fotos in einer Galerie hochladen, um sie mit anderen zu teilen.",
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1958772740/Wie+f+ge+ich+Bilder+einer+Veranstaltung+hinzu+und+wie+funktioniert+die+Eventgalerie",
        tags: ["Veranstaltungen", "Fotos", "Digest"],
    },
    {
        image: "/images/changelog/folder-download.png",
        title: "Ordner-Download",
        date: "19.10.2023",
        description: "In der Dateiablage ist es nun möglich sowohl alle Dateien in einem Ordner, als auch eine ganze Ordnerstruktur (nur für Admins) herunterzuladen.",
        tags: ["Dateiablage", "Export"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/125829332/Dokumente+oder+Bilder+herunterladen+oder+exportieren",
    },
    {
        image: "/images/changelog/anmeldeexport.png",
        title: "Export von Teilnehmerlisten",
        date: "28.09.2023",
        description: "Alle Details zu An- und Abmeldungen zu einer Veranstaltung können nun als Excel-Datei exportiert werden.",
        tags: ["Veranstaltungen", "Export", "Organisation"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1932394501/Verwaltbare+Teilnehmerlisten",
    },
    {
        image: "/images/changelog/attachments.png",
        title: "Dateianhänge",
        date: "05.09.2023",
        description: "Endlich ist es möglich, Dateien wie Einladungen oder Protokolle direkt in Diskussionen und Veranstaltungen anzuhängen.",
        tags: ["Kommunikation", "Veranstaltungen", "Dateien"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1937670145/Wie+f+ge+ich+einer+neuen+Veranstaltung+oder+Diskussion+eine+Datei+als+Anhang+Attachment+hinzu",
    },
    {
        image: "/images/changelog/repair-push.png",
        title: "Push-Nachrichten Assistent",
        date: "29.06.2023",
        description: "(Offene Beta) Ein Einrichtungs- und Reparaturassistent hilft Ihnen bei der Einrichtung von Push-Nachrichten auf Ihrem iOS 16.4+ oder in Chrome/Android Smartphone oder Tablet. In iOS muss die App dem Homescreen hinzugefügt sein.",
        tags: ["Push-Nachrichten", "App"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1937637387/Wie+abonniere+ich+Push-Benachrichtigungen+auf+meinem+Smartphone+oder+Computer",
    },
    {
        image: "/images/changelog/urgent_topic_pn.png",
        title: "Push-Nachricht bei dringenden Nachrichten",
        date: "25.05.2023",
        description: "(Geschlossene Beta) Erster Pilot-Test mit Push-Nachrichten, aktuell beschränkt auf Admins: Dringende Diskussionen erhalten Gruppenmitglieder, die Push-Nachrichten verwenden, als solche direkt auf ihr Smartphone oder Tablet.",
        tags: ["App", "Push-Nachrichten", "Diskussionen"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1952776193/Welche+Push-Nachrichten+gibt+es",
    },
    {
        image: "/images/changelog/attends.gif",
        title: "Anmeldungen zu Veranstaltungen verwalten",
        date: "22.01.2023",
        description: "Den Organisatoren einer Veranstaltung ist es nun möglich, die Zu- und Absagen der Teilnehmer sowie deren Gästezahl zu verwalten, um auch telefonische Rückmeldungen berücksichtigen zu können.",
        tags: ["Veranstaltungen", "Organisation"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1932394501/Verwaltbare+Teilnehmerlisten",
    },
    {
        image: "/images/changelog/email-verification.png",
        title: "E-Mail-Verifikation",
        date: "01.10.2022",
        description: "Im Profil sowie Export wird nun angezeigt, ob ein E-Mail-Postfach grundsätzlich gelesen wird. Sollte eine E-Mail in den letzten 3 Monaten geöffnet worden oder ein Link daraus geklickt worden sein, wissen wir, dass das Postfach wirklich benutzt wird. Eine manuelle Bestätigung im Profil ist auch möglich.",
        tags: ["Verwaltung", "Profil", "Export"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1979646023/E-Mail-Verifikation",
    },
    {
        image: "/images/changelog/ZuAbsageICal.png",
        title: "Zu-/Absagen direkt aus iCal-Einbindung",
        date: "28.09.2022",
        description: "Die Veranstaltungsbeschreibung im iCal-Feed für die Kalenderintegration ins Smartphone enthält nun Links, um direkt zu- oder abzusagen. Bei einer Absage verschwindet das Event, sobald der Kalender das nächste Mal synchronisiert wird.",
        tags: ["Veranstaltungen", "Kalender"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1573781505/Wie+sage+ich+zu+einer+Veranstaltung+zu+oder+ab",
    },
    {
        image: "/images/changelog/NeueEmojis.png",
        title: "Modernisierte Oberfläche für Diskussionen und Veranstaltungen",
        date: "20.07.2022",
        description: "Die Modernisierung der Seiten zum Erstellen und Ansehen von Diskussionen und Veranstaltungen ist abgeschlossen. Sie sind nun einfacher und schneller zu bedienen – insbesondere mobil. Zusätzlich kann man jetzt vielfältiger mit Emojis auf Beiträge reagieren.",
        tags: ["Diskussionen", "Veranstaltungen"],
    },
    {
        image: "/images/changelog/ErinnerungenAnmeldung.gif",
        title: "Einstellbare Erinnerungen für Veranstaltungen",
        date: "15.02.2022",
        description: "Beim Anlegen einer Veranstaltung kann nun eingestellt werden, ob und wann Erinnerungen zur Anmeldung im Digest erscheinen sollen.",
        tags: ["Veranstaltungen", "Organisation"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1894350849/Wie+erstelle+ich+eine+Veranstaltung",
    },
    {
        title: "Verbesserte Verbands-Funktionen",
        date: "15.01.2022",
        description: "Einführung einer eigenen Statistik-Seite für Verbände sowie differenzierte Berechtigungen im Verband. Dazu können die verfügbaren Berechtigungen auf Verbands- und Verbindungsebene eingeschränkt werden. Nur für Nutzer der Verbandsfunktionen.",
        tags: ["Verwaltung", "Verband"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1978794112/Verbands-Statistiken",
    },
    {
        image: "/images/changelog/GaesteHinzufuegen.gif",
        title: "Anmeldungen mit Gästen",
        date: "03.01.2022",
        description: "Bei Veranstaltungen können nun auch Gäste mit angemeldet werden.",
        tags: ["Veranstaltungen", "Organisation"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1979613210/Best-Practices+zu+Veranstaltungen",
    },
    {
        image: "/images/changelog/level_pages.gif",
        title: "Verbandsseiten",
        date: "03.01.2022",
        description: "Übergreifende Gruppen im Verband können nun eine eigene Seite mit Text, Ämtern etc. erhalten. Nur für Nutzer der Verbandsfunktionen.",
        tags: ["Verband"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1979285526/Verbandsseiten",
    },
    {
        image: "/images/changelog/AnmeldenAusEMail.png",
        title: "Direkt aus dem Digest absagen",
        date: "03.01.2022",
        description: "Aus dem persönlichen Digest heraus kann jetzt ohne Passworteingabe direkt zu- oder abgesagt werden.",
        tags: ["Veranstaltungen", "Digest"],
        link: "https://suus.atlassian.net/wiki/spaces/GSD/pages/1573781505/Wie+sage+ich+zu+einer+Veranstaltung+zu+oder+ab",
    },
    {
        image: "/images/changelog/Suche.gif",
        title: "Schnellsuche für Mitglieder",
        date: "15.12.2021",
        description: "Es gibt nun eine Schnellsuche für Mitglieder oben rechts in der Navigation. Diese verzeiht auch Tippfehler.",
        tags: ["Verwaltung", "Suche"],
    },
    {
        title: "...und vieles mehr",
        date: "01.12.2021",
        description: "Es gibt noch viele weitere Verbesserungen, die schon länger her sind oder zu kleinteilig, um Sie hier aufzuführen. " +
            "Wir arbeiten ständig daran, die Plattform zu verbessern. Wenn Sie Fragen oder Anregungen haben, schreiben Sie uns gerne an!",
    },
].map(function (item) { return (__assign(__assign({}, item), { date: parseGermanDate(item.date) })); });
function parseGermanDate(date) {
    var _a = date.split("."), day = _a[0], month = _a[1], year = _a[2];
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}
