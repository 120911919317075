import { computed, defineComponent, ref, } from "vue";
import Subtitle from "components/UI/Subtitle.vue";
import TextBody from "components/UI/TextBody.vue";
import TextButton from "components/UI/TextButton.vue";
import "features/common/confirmation-dialog/confirmation.service";
import useTranslate from "features/common/translate/concerns/useTranslate";
import { useIsMobile } from "features/common/vuetify/useVuetify";
var t$ = useTranslate("components.confirmation_dialog").t$;
var DEFAULT_CONFIRMATION_TITLE = t$(".title");
var DEFAULT_CONFIRMATION_TEXT = t$(".text");
var DEFAULT_CONFIRM_BUTTON_LABEL = t$(".confirm");
var DEFAULT_CANCEL_BUTTON_LABEL = t$(".cancel");
export default defineComponent({
    name: "ConfirmationDialog",
    components: { TextButton: TextButton, TextBody: TextBody, Subtitle: Subtitle },
    props: {
        confirmationService: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var _dialog = ref(false);
        var dialog = computed({
            get: function () { return _dialog.value; },
            set: function (value) { return (_dialog.value = value); },
        });
        var confirmationTitle = ref(DEFAULT_CONFIRMATION_TITLE);
        var confirmationText = ref(DEFAULT_CONFIRMATION_TEXT);
        var confirmButtonLabel = ref(DEFAULT_CONFIRM_BUTTON_LABEL);
        var cancelButtonLabel = ref(DEFAULT_CANCEL_BUTTON_LABEL);
        var _reset = function () {
            confirmationTitle.value = DEFAULT_CONFIRMATION_TITLE;
            confirmationText.value = DEFAULT_CONFIRMATION_TEXT;
            confirmButtonLabel.value = DEFAULT_CONFIRM_BUTTON_LABEL;
            cancelButtonLabel.value = DEFAULT_CANCEL_BUTTON_LABEL;
        };
        var _handleConfirm = ref(function () {
            //pass
        });
        var _handleCancel = ref(function () {
            //pass
        });
        var _handleOpenConfirmation = function (title, text, confirmText, cancelText) {
            confirmationTitle.value = title || DEFAULT_CONFIRMATION_TITLE;
            confirmationText.value = text || DEFAULT_CONFIRMATION_TEXT;
            confirmButtonLabel.value = confirmText || DEFAULT_CONFIRM_BUTTON_LABEL;
            cancelButtonLabel.value = cancelText || DEFAULT_CANCEL_BUTTON_LABEL;
            dialog.value = true;
            return new Promise(function (resolve) {
                var handleConfirm = function () {
                    dialog.value = false;
                    resolve(true);
                };
                var handleCancel = function () {
                    dialog.value = false;
                    resolve(false);
                };
                _handleConfirm.value = handleConfirm;
                _handleCancel.value = handleCancel;
            });
        };
        props.confirmationService.registerCallback(_handleOpenConfirmation);
        var handleConfirm = function () {
            _handleConfirm.value();
            _reset();
        };
        var handleCancel = function () {
            _handleCancel.value();
            _reset();
        };
        var isMobile = useIsMobile();
        var buttonStyles = computed(function () {
            return isMobile.value
                ? {
                    flex: "1 1 0",
                }
                : {};
        });
        return {
            dialog: dialog,
            confirmationTitle: confirmationTitle,
            confirmationText: confirmationText,
            confirmButtonLabel: confirmButtonLabel,
            cancelButtonLabel: cancelButtonLabel,
            handleConfirm: handleConfirm,
            handleCancel: handleCancel,
            buttonStyles: buttonStyles,
        };
    },
});
