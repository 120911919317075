import { __assign } from "tslib";
import THEME from "../../constants/theme";
import { ref, computed, defineComponent, watch } from "vue";
import "suus-api";
import "interfaces/menuItem.interface";
import CommentBlock from "./CommentBlock.vue";
import CommentForm from "./CommentForm.vue";
import CommentLoading from "./CommentLoading.vue";
import { mdiPencilOutline } from "@mdi/js";
import { mdiDeleteOutline } from "@mdi/js";
import "concerns/useContextName";
import { useConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
export default defineComponent({
    name: "Comment",
    components: { CommentBlock: CommentBlock, CommentForm: CommentForm, CommentLoading: CommentLoading },
    props: {
        comment: {
            type: Object,
            required: true,
        },
        viewContext: { type: Object },
    },
    setup: function (props, context) {
        var initialCommentText = ref(props.comment.text);
        var commentText = ref(props.comment.text);
        var isEdit = ref(false);
        function saveComment(val) {
            commentText.value = val;
            var updatedComment = __assign(__assign({}, props.comment), { text: commentText.value });
            context.emit("save", updatedComment);
            isEdit.value = false;
        }
        function cancelComment() {
            commentText.value = initialCommentText.value;
            isEdit.value = false;
        }
        var confirmationService = useConfirmationService();
        var menuItems = computed(function () {
            return [
                {
                    title: "Bearbeiten",
                    key: "edit",
                    clickHandler: function () { return (isEdit.value = true); },
                    icon: mdiPencilOutline,
                    permitted: !!props.comment.links.update_url,
                },
                {
                    title: "Löschen",
                    key: "delete",
                    clickHandler: function () {
                        confirmationService.open({
                            title: "Kommentar löschen",
                            text: "Möchtest du diesen Kommentar wirklich löschen?",
                            confirmText: "Löschen",
                            cancelText: "Abbrechen",
                            onConfirm: function () {
                                context.emit("delete", props.comment.id);
                            },
                        });
                    },
                    icon: mdiDeleteOutline,
                    permitted: !!props.comment.links.delete_url,
                },
            ];
        });
        var permittedMenuItems = computed(function () {
            return menuItems.value.filter(function (item) { return item.permitted; });
        });
        var cardStyles = {
            borderRadius: "4px",
            border: "1px solid ".concat(THEME.colors.light.mediumGrey),
            backgroundColor: THEME.colors.light.lightGrey,
        };
        watch(function () { return props.comment; }, function (newVal) {
            commentText.value = newVal.text;
        });
        return {
            isEdit: isEdit,
            commentText: commentText,
            saveComment: saveComment,
            cancelComment: cancelComment,
            permittedMenuItems: permittedMenuItems,
            cardStyles: cardStyles,
        };
    },
});
