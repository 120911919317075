import { defineComponent, ref, watch } from "vue";
import FieldLabel from "components/UI/FieldLabel.vue";
import { mdiCheckCircle } from "@mdi/js";
import { useVuetify } from "features/common/vuetify/useVuetify";
export default defineComponent({
    name: "ContentfulSelect",
    components: { FieldLabel: FieldLabel },
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var selectedOption = ref(props.value);
        watch(function () { return props.value; }, function (value) {
            selectedOption.value = value;
        });
        var vuetify = useVuetify();
        var listStyles = {
            borderBottom: "1px solid ".concat(vuetify.theme.themes.light.lightGrey),
            flexWrap: "nowrap",
        };
        var setIconColor = function (value) {
            return value === selectedOption.value ? "primary" : "grey";
        };
        watch(function () { return selectedOption.value; }, function (value) {
            emit("input", value);
        });
        return {
            selectedOption: selectedOption,
            mdiCheckCircle: mdiCheckCircle,
            listStyles: listStyles,
            setIconColor: setIconColor,
        };
    },
});
