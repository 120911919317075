import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { computed, defineComponent, ref, watch } from "vue";
import { mdiClose, mdiDrag, mdiPlus } from "@mdi/js";
import Button from "components/UI/Button.vue";
import "suus-api";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import CustomAttributesList from "app/modules/custom-attributes/CustomAttributesList.vue";
import Header4 from "app/ui-primitives/header/Header4.vue";
import CustomAttributesCreate from "app/modules/custom-attributes/CustomAttributesCreateDialog.vue";
import { useConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
import ErrorAlert from "app/ui-primitives/alerts/ErrorAlert.vue";
import ErrorSnackbar from "app/ui-primitives/snackbars/ErrorSnackbar.vue";
import SuccessSnackbar from "app/ui-primitives/snackbars/SuccessSnackbar.vue";
import "app/modules/custom-attributes/types";
/**
 * RESPONSIBILITIES:
 *  The Management Component for Custom Attributes has the following responsibilities:
 *    - Handling the shared state of Custom Attributes (all fetched custom attributes) and all operation on them
 *    - Splitting the Custom Attributes into two lists and handling the reordering of the items in the lists
 *    - Building the outer UI structure
 *  It explicitly does not have the responsibility for the following:
 *    - Handling the loading, error and success states of single custom attributes
 */
export default defineComponent({
    name: "CustomAttributesManagement",
    components: {
        SuccessSnackbar: SuccessSnackbar,
        ErrorSnackbar: ErrorSnackbar,
        ErrorAlert: ErrorAlert,
        CustomAttributesCreate: CustomAttributesCreate,
        Header4: Header4,
        CustomAttributesList: CustomAttributesList,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
        Button: Button,
    },
    props: {
        /**
         *  Using Functions as a prop instead of emitting events,
         *  because this allows to handle loading states and errors in this component.
         */
        fetch: {
            type: Function,
            required: true,
        },
        create: {
            type: Function,
            required: true,
        },
        update: {
            type: Function,
            required: true,
        },
        reorder: {
            type: Function,
            required: true,
        },
        // "delete" is a reserved keyword in JavaScript
        deleteFn: {
            type: Function,
            required: true,
        },
        batchUpdate: {
            type: Function,
            required: true,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var customAttributes = ref([]);
        var loadingAttributes = ref(true);
        var fetchError = ref(null);
        props
            .fetch()
            .then(function (attributes) {
            loadingAttributes.value = false;
            customAttributes.value = attributes;
        })
            .catch(function (error) {
            console.error(error);
            loadingAttributes.value = false;
            fetchError.value =
                "Beim Laden der Attribute ist ein Fehler aufgetreten. Versuche die Seite neu zu laden.";
        });
        var creationDialog = ref(false);
        var handleCreation = function (customAttribute) { return __awaiter(_this, void 0, void 0, function () {
            var createdAttribute;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.create(customAttribute)];
                    case 1:
                        createdAttribute = _a.sent();
                        customAttributes.value = __spreadArray(__spreadArray([], customAttributes.value, true), [createdAttribute], false);
                        creationDialog.value = false;
                        // Return the created attribute so the list child components can handle it if needed.
                        return [2 /*return*/, createdAttribute];
                }
            });
        }); };
        var handleUpdate = function (customAttribute) { return __awaiter(_this, void 0, void 0, function () {
            var updatedAttributeOrUpdateErrors, updatedAttribute, index, copy;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.update(customAttribute)
                        // If Errors, return them so the list child components can handle them.
                    ];
                    case 1:
                        updatedAttributeOrUpdateErrors = _a.sent();
                        // If Errors, return them so the list child components can handle them.
                        if (updatedAttributeOrUpdateErrors instanceof Array) {
                            return [2 /*return*/, updatedAttributeOrUpdateErrors];
                        }
                        updatedAttribute = updatedAttributeOrUpdateErrors;
                        index = customAttributes.value.findIndex(function (attr) { return attr.id === updatedAttribute.id; });
                        copy = __spreadArray([], customAttributes.value, true);
                        copy[index] = updatedAttribute;
                        customAttributes.value = copy;
                        // Return the updated attribute so the list child components can handle it if needed.
                        return [2 /*return*/, updatedAttribute];
                }
            });
        }); };
        var deleteAttribute = function (id) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, props.deleteFn(id)];
                    case 1:
                        _a.sent();
                        customAttributes.value = customAttributes.value.filter(function (attr) { return attr.id !== id; });
                        snackbarSuccessMessage.value = "Feld erfolgreich gelöscht.";
                        showSuccessSnackbar.value = true;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.warn(error_1);
                        snackbarErrorMessage.value =
                            "Das Feld konnte nicht gelöscht werden. Stell bitte sicher, dass keine Werte mehr dafür gespeichert sind und versuche es dann erneut.";
                        showErrorSnackbar.value = true;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        /**
         *  TWO LISTS & REORDERING
         *  [SIMPLIFICATION]:
         *    If the BE either accounts for two lists or provides an endpoint that encapsulates the reordering logic, the reordering logic can be simplified.
         */
        var personalRecordAttributes = computed(function () {
            return customAttributes.value.filter(function (attr) { return attr.attributable_type === "PersonalRecord"; });
        });
        var clubRecordAttributes = computed(function () {
            return customAttributes.value.filter(function (attr) { return attr.attributable_type === "ClubRecord"; });
        });
        var listIdPersonal = "personal-records";
        var listIdClub = "club-records";
        var personalRecordsListKey = ref(0);
        var clubRecordsListKey = ref(0);
        var findAttribute = function (listId, index) {
            return listId === listIdPersonal
                ? personalRecordAttributes.value[index]
                : clubRecordAttributes.value[index];
        };
        var findSharedIndex = function (customAttribute) {
            return customAttributes.value.findIndex(function (attr) { return attr.id === customAttribute.id; });
        };
        // TODO: Implement Reorder Error Snackbar + Optimistic Update
        var handleReorder = function (event) {
            var isSameList = event.from === event.to;
            var movedAttribute = findAttribute(event.from, event.oldIndex);
            var targetAttribute = findAttribute(event.to, event.newIndex);
            if (isSameList) {
                _handleReorderInSameList(movedAttribute, targetAttribute);
            }
            else {
                _handleReorderBetweenLists(event, movedAttribute, targetAttribute);
            }
        };
        var confirmationService = useConfirmationService();
        var resetOrder = function () {
            // Revert the reordering & return
            // The reordering has to be reset by rerendering the lists completely. We can exploit that we haven't yet commited the move in the shared state.
            // SortableJS does not provide a way to cancel the reordering in **multiple** lists.
            // This is the closest alternative solution in SortableJs: [https://github.com/SortableJS/Sortable/issues/266#issuecomment-1619729909]
            personalRecordsListKey.value++;
            clubRecordsListKey.value++;
        };
        var _handleReorderBetweenLists = function (reorderEvent, movedAttribute, targetAttribute) { return __awaiter(_this, void 0, void 0, function () {
            function getSharedNewIndex(oldIndex) {
                if (!targetAttribute) {
                    return customAttributes.value.length - 1; // No target means the item was moved to the end of another list
                }
                var _newIndex = findSharedIndex(targetAttribute);
                return _newIndex > oldIndex ? _newIndex - 1 : _newIndex; // If the target is in another list, there is no swapping of items, that's why the index needs to be adjusted
            }
            var isFromClubToPersonal, isConfirmed, sharedOldIndex, sharedNewIndex, changedAttributableType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isFromClubToPersonal = reorderEvent.from === listIdClub && reorderEvent.to === listIdPersonal;
                        if (!isFromClubToPersonal) return [3 /*break*/, 2];
                        return [4 /*yield*/, confirmationService.open({
                                title: "Warnung",
                                text: "Wenn du das Feld von den Mitgliedschaftsdaten zur Personendaten verschiebst, werden nur die Daten der eigenen Verbindung übertragen. Die Daten der Mitgliedschaftsdaten werden gelöscht und können nicht wiederhergestellt werden.",
                                confirmText: "Bestätigen",
                                cancelText: "Abbrechen",
                            })];
                    case 1:
                        isConfirmed = _a.sent();
                        if (!isConfirmed) {
                            resetOrder();
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        sharedOldIndex = findSharedIndex(movedAttribute);
                        sharedNewIndex = getSharedNewIndex(sharedOldIndex);
                        changedAttributableType = reorderEvent.to === listIdPersonal ? "PersonalRecord" : "ClubRecord";
                        /**
                         * COMMITTING THE REORDER
                         */
                        _commitReorder(movedAttribute, sharedOldIndex, sharedNewIndex, changedAttributableType);
                        return [2 /*return*/];
                }
            });
        }); };
        var _handleReorderInSameList = function (movedAttribute, targetAttribute) {
            var sharedOldIndex = findSharedIndex(movedAttribute);
            var sharedNewIndex = findSharedIndex(targetAttribute);
            _commitReorder(movedAttribute, sharedOldIndex, sharedNewIndex);
        };
        var _commitReorder = function (attribute, oldIndex, newIndex, changedAttributableType) { return __awaiter(_this, void 0, void 0, function () {
            var copy, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, props.reorder(attribute.id, newIndex, changedAttributableType)
                            /** Expected Update */
                        ];
                    case 1:
                        _a.sent();
                        copy = __spreadArray([], customAttributes.value, true);
                        copy.splice(oldIndex, 1);
                        // Change the attributable type if the attribute was moved to the other list
                        copy.splice(newIndex, 0, __assign(__assign({}, attribute), { attributable_type: changedAttributableType !== null && changedAttributableType !== void 0 ? changedAttributableType : attribute.attributable_type }));
                        customAttributes.value = copy;
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        snackbarErrorMessage.value = "Fehler beim Verschieben des Attributs.";
                        showErrorSnackbar.value = true;
                        resetOrder();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var snackbarErrorMessage = ref("");
        var showErrorSnackbar = ref(false);
        var snackbarSuccessMessage = ref("");
        var showSuccessSnackbar = ref(false);
        watch(function () { return customAttributes.value; }, function () {
            emit("customAttributesChanged", customAttributes.value);
        }, { immediate: true });
        return {
            mdiDrag: mdiDrag,
            mdiPlus: mdiPlus,
            handleUpdate: handleUpdate,
            handleReorder: handleReorder,
            handleCreation: handleCreation,
            creationDialog: creationDialog,
            mdiClose: mdiClose,
            personalRecordAttributes: personalRecordAttributes,
            clubRecordAttributes: clubRecordAttributes,
            listIdPersonal: listIdPersonal,
            listIdClub: listIdClub,
            personalRecordsListKey: personalRecordsListKey,
            clubRecordsListKey: clubRecordsListKey,
            deleteAttribute: deleteAttribute,
            loadingAttributes: loadingAttributes,
            fetchError: fetchError,
            snackbarErrorMessage: snackbarErrorMessage,
            showErrorSnackbar: showErrorSnackbar,
            snackbarSuccessMessage: snackbarSuccessMessage,
            showSuccessSnackbar: showSuccessSnackbar,
        };
    },
});
