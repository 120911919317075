import { __assign } from "tslib";
import { defineComponent } from "vue";
import THEME from "constants/theme";
export default defineComponent({
    name: "ErrorText",
    props: {},
    setup: function () {
        return {
            errorMessageStyles: __assign({}, THEME.typography.inputMessage),
        };
    },
});
