import { computed, defineComponent } from "vue";
import ContentfulSelect from "app/ui-primitives/forms/ContentfulSelect.vue";
import "suus-api";
import { mdiCalendar, mdiCalendarStar, mdiCheckboxMarked, mdiDecimalComma, mdiFormatTitle, mdiFormDropdown, mdiNumeric, mdiText, } from "@mdi/js";
export var ATTRIBUTE_TYPE_SELECTION_ITEMS = [
    {
        text: "Ganze Zahl",
        value: "Integer",
        icon: mdiNumeric,
        description: "<p>Erstellt ein Feld, das nur ganze Zahlen akzeptiert.</p><i>Beispiele: -3 | 32</i>",
    },
    {
        text: "Kommazahl",
        value: "Float",
        icon: mdiDecimalComma,
        description: "<p>Erstellt ein Feld, das Kommazahlen akzeptiert.</p><i>Beispiele: -0.2 | 0 | 1024.0</i>",
    },
    {
        text: "Text",
        value: "String",
        icon: mdiFormatTitle,
        description: "<p>Erstellt ein <b>einzeiliges, unformatiertes</b> Textfeld.</p><i>Beispiele: 'Beliebiger Freitext ohne Formatierung'</i>",
    },
    {
        text: "Textblock",
        value: "Text",
        icon: mdiText,
        description: "<p>Erstellt ein <b>mehrzeiliges, formatierbares</b> Feld, das freie Texteingabe akzeptiert.</p><i>Beispiele: 'Text mit <u>Formatierung</u>!'</i>",
    },
    {
        text: "Checkbox",
        value: "Boolean",
        icon: mdiCheckboxMarked,
        description: "Erstellt eine Auswahlfeld, das entweder angehakt oder nicht angehakt sein kann.",
    },
    {
        text: "Genaues Datum",
        value: "Date",
        icon: mdiCalendar,
        description: "<p>Erstellt ein Feld, das ein genaues Datum akzeptiert.</p><i>Beispiel: 20.10.2024</i>",
    },
    {
        text: "Ungefähres Datum",
        value: "FuzzyDate",
        icon: mdiCalendarStar,
        description: "<p>Erstellt ein Feld, das ein ungefähres Datum akzeptiert. </p><i>Beispiele: 22.11.1870 | Januar 2022 | WS 22/23 | 2011</i>",
    },
    {
        text: "Auswahl",
        value: "Select",
        icon: mdiFormDropdown,
        description: "<p>Erstellt ein Feld, das eine Auswahl von vordefinierten Werten akzeptiert.</p>",
    },
];
/**
 *  props: attributeType
 *  emits: update:attributeType
 *  props: selectionValues
 *  emits: update:selectionValues
 *
 *  VALIDATION OUTSIDE OF COMPONENT
 */
export default defineComponent({
    name: "CustomAttributeTypeSelection",
    components: { ContentfulSelect: ContentfulSelect },
    props: {
        attributeType: {
            type: String,
            required: true,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var selectedAttributeSelectionItem = computed(function () {
            return ATTRIBUTE_TYPE_SELECTION_ITEMS.find(function (item) { return item.value === props.attributeType; });
        });
        var handleTypeSelection = function (selection) {
            emit("update:attributeType", selection);
        };
        return {
            ATTRIBUTE_TYPE_SELECTION_ITEMS: ATTRIBUTE_TYPE_SELECTION_ITEMS,
            selectedAttributeSelectionItem: selectedAttributeSelectionItem,
            handleTypeSelection: handleTypeSelection,
        };
    },
});
