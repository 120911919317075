import { __assign } from "tslib";
import { ref, computed, defineComponent, watch } from "vue";
import THEME from "../../constants/theme";
import { HTMlfromString, getTextContextLength } from "helpers/parseHTML";
import useMentionSearch from "concerns/useMentionSearch";
import "concerns/useContextName";
import BasicWysiwyg from "components/Wysiwyg/BasicWysiwyg.vue";
import { useConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
import { useThemeColors } from "features/common/theme/useTheme";
export default defineComponent({
    name: "CommentForm",
    components: { BasicWysiwyg: BasicWysiwyg },
    props: {
        viewContext: { type: Object },
        value: {
            type: String,
            default: "",
        },
        inline: { type: Boolean, default: false },
    },
    setup: function (props, context) {
        var themeColors = useThemeColors();
        var formValue = ref(props.value);
        watch(props, function (props) { return (formValue.value = props.value); });
        var errorText = ref("");
        var disabled = computed(function () { return !!errorText.value.length; });
        var commentRules = [
            function (v) {
                var isEmpty = (!v && !v.length) || v.toString() === "<p></p>";
                return isEmpty ? "Bitte gib ein Kommentar ein." : "";
            },
            function (v) {
                var textLength = getTextContextLength(HTMlfromString(v));
                return textLength > 300
                    ? "Dein Kommentar überschreitet das Maximum von 300 Zeichen."
                    : "";
            },
        ];
        function validateComment(comment) {
            errorText.value = commentRules.reduce(function (errors, rule) { return errors + rule(comment); }, "");
            return !errorText.value;
        }
        function handleSubmit(value) {
            if (!validateComment(formValue.value))
                return;
            context.emit("save", formValue.value);
            formValue.value = "";
        }
        var buttonLabelStyles = computed(function () { return (__assign({ color: disabled.value ? themeColors.mediumGrey : themeColors.primary }, THEME.typography.button)); });
        var cancelButtonLabelStyles = computed(function () { return (__assign({ color: themeColors.darkGrey }, THEME.typography.button)); });
        var buttonContainerStyles = {
            backgroundColor: "#fff",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            border: "1px solid ".concat(themeColors.mediumGrey),
        };
        var wysiwygContainerStyles = {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            border: "1px solid ".concat(themeColors.mediumGrey),
            borderBottom: "none",
        };
        var errorMessageStyles = __assign({}, THEME.typography.inputMessage);
        var getMentionableFunc = useMentionSearch(props.viewContext).mentionableFunction;
        var confirmationService = useConfirmationService();
        var _commitReset = function () {
            context.emit("cancel");
            formValue.value = props.value;
            errorText.value = "";
        };
        var cancelAndReset = function () {
            if (!formValue.value) {
                _commitReset();
                return;
            }
            confirmationService.open({
                title: "Änderungen verwerfen?",
                text: "Möchtest du wirklich deine Änderungen verwerfen?",
                confirmText: "Verwerfen",
                cancelText: "Abbrechen",
                onConfirm: _commitReset,
            });
        };
        return {
            formValue: formValue,
            getMentionableFunc: getMentionableFunc,
            buttonLabelStyles: buttonLabelStyles,
            buttonContainerStyles: buttonContainerStyles,
            wysiwygContainerStyles: wysiwygContainerStyles,
            cancelButtonLabelStyles: cancelButtonLabelStyles,
            errorMessageStyles: errorMessageStyles,
            validateComment: validateComment,
            handleSubmit: handleSubmit,
            cancelAndReset: cancelAndReset,
            errorText: errorText,
            disabled: disabled,
        };
    },
});
