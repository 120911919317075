import { computed, defineComponent, ref, watch, } from "vue";
import TextBody from "components/UI/TextBody.vue";
import SuusTextField from "app/ui-primitives/forms/SuusTextField.vue";
export default defineComponent({
    name: "ToggleableTextField",
    components: { SuusTextField: SuusTextField, TextBody: TextBody },
    props: {
        fieldEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
        switchLabel: {
            type: String,
            required: false,
            default: "",
        },
        fieldPlaceholder: {
            type: String,
            required: false,
            default: "",
        },
        fieldLabel: {
            type: String,
            required: false,
            default: "",
        },
        fieldInfo: {
            type: String,
            required: false,
            default: null,
        },
        disableIfEmpty: {
            type: Boolean,
            required: false,
            default: false,
        },
        buttonText: {
            type: String,
            required: false,
            default: "",
        },
        disableButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup: function (props, context) {
        var fieldEnabledRef = ref(props.fieldEnabled);
        watch(function () { return props.fieldEnabled; }, function (value) {
            if (props.disableIfEmpty && !value) {
                return;
            }
            fieldEnabledRef.value = value;
        });
        var fieldEnabledModel = computed({
            get: function () { return fieldEnabledRef.value; },
            set: function (value) {
                setFieldEnabled(value);
            },
        });
        var setFieldEnabled = function (value) {
            fieldEnabledRef.value = !!value;
            context.emit("update:fieldEnabled", !!value);
            if (!value) {
                context.emit("input", null);
            }
        };
        var valueModel = computed({
            get: function () { return props.value; },
            set: function (value) {
                context.emit("input", value);
            },
        });
        var disableIfEmptyFunction = function () {
            if (props.disableIfEmpty && !valueModel.value) {
                fieldEnabledRef.value = false;
            }
        };
        return {
            fieldEnabledModel: fieldEnabledModel,
            fieldEnabledRef: fieldEnabledRef,
            setFieldEnabled: setFieldEnabled,
            valueModel: valueModel,
            disableIfEmptyFunction: disableIfEmptyFunction,
        };
    },
});
