import { defineComponent } from "vue";
import Header4 from "app/ui-primitives/header/Header4.vue";
import "app/modules/custom-attributes/api";
export default defineComponent({
    name: "CustomAttributesUpdateErrors",
    components: { Header4: Header4 },
    props: {
        updateErrors: {
            type: Array,
            required: true,
        },
    },
});
