import { computed, defineComponent } from "vue";
import { useThemeColors } from "features/common/theme/useTheme";
import "constants/theme";
export default defineComponent({
    name: "Header4",
    props: {
        color: {
            type: String,
            default: "textColor",
        },
    },
    setup: function (props) {
        var themeColors = useThemeColors();
        var textColor = computed(function () { return themeColors[props.color]; });
        return {
            textColor: textColor,
        };
    },
});
