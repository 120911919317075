import { __awaiter, __generator } from "tslib";
import { defineComponent, ref, watch } from "vue";
import "suus-api";
import "axios";
import useTranslate from "features/common/translate/concerns/useTranslate";
import Button from "components/UI/Button.vue";
import Header from "components/UI/Header.vue";
import CloseableDialog from "components/UI/CloseableDialog.vue";
import Header4 from "app/ui-primitives/header/Header4.vue";
import "app/modules/custom-attributes/types";
export default defineComponent({
    name: "CustomAttributeValueUpdater",
    components: { Header4: Header4, CloseableDialog: CloseableDialog, Header: Header, Button: Button },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        customAttribute: {
            type: Object,
            required: true,
        },
        batchUpdate: {
            type: Function,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var selectedLevel = ref(null);
        var file = ref(null);
        var t$ = useTranslate("components.custom_attribute_batch_updater").t$;
        var open = ref(props.value);
        watch(function () { return props.value; }, function (value) {
            open.value = value;
        });
        watch(function () { return open.value; }, function (value) {
            emit("input", value);
        });
        var message = ref("");
        var loading = ref(false);
        var successCount = ref(0);
        var errors = ref([]);
        var uploadFile = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, data, error_1, errData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        loading.value = true;
                        message.value = "";
                        successCount.value = 0;
                        errors.value = [];
                        return [4 /*yield*/, props.batchUpdate(props.customAttribute.id, file.value)];
                    case 1:
                        response = _a.sent();
                        if ("data" in response) {
                            data = response.data;
                            errors.value = data.records_not_updated;
                            successCount.value = data.updated_records_count;
                        }
                        else {
                            message.value =
                                "Es ist ein Fehler aufgetreten (" + response.statusText + ")";
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        // Check type if an axios error
                        if (error_1.isAxiosError) {
                            if (error_1.response.status === 423) {
                                message.value =
                                    "Es ist bereits ein Update-Vorgang dieses Attributes im Gange, bitte probiere es später erneut.";
                                console.warn(error_1);
                            }
                            else {
                                errData = error_1.response
                                    .data;
                                message.value = "Server-Fehler beim Aktualisieren der Eintr\u00E4ge: ".concat(errData.error, " (").concat(errData.code, "): ").concat(errData.message);
                                console.error(error_1);
                            }
                        }
                        else {
                            message.value = "Unspezifzierter Fehler beim Aktualisieren der Eintr\u00E4ge: ".concat(error_1);
                            console.error(error_1);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return {
            selectedLevel: selectedLevel,
            file: file,
            uploadFile: uploadFile,
            message: message,
            loading: loading,
            successCount: successCount,
            errors: errors,
            t$: t$,
            open: open,
        };
    },
    methods: {
        csvFileRules: function () {
            return [
                function (v) { return !!v || "Eine Datei muss ausgewählt werden."; },
                function (v) {
                    return (v === null || v === void 0 ? void 0 : v.type) === "text/csv" || "Die Datei muss eine CSV-Datei sein.";
                },
                function (v) { return (v === null || v === void 0 ? void 0 : v.size) < 1000000 || "Die Datei ist größer als 1MB."; },
            ];
        },
    },
});
