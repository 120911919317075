import { inject } from "vue";
var ConfirmationService = /** @class */ (function () {
    function ConfirmationService() {
    }
    ConfirmationService.prototype.open = function (_a) {
        var title = _a.title, text = _a.text, confirmText = _a.confirmText, cancelText = _a.cancelText, onConfirm = _a.onConfirm;
        if (!this._callback) {
            throw new Error("No callback was registered in ConfirmationService");
        }
        return this._callback(title, text, confirmText, cancelText).then(function (confirmed) {
            if (confirmed && onConfirm) {
                onConfirm();
            }
            return confirmed;
        });
    };
    ConfirmationService.prototype.registerCallback = function (callback) {
        this._callback = callback;
    };
    return ConfirmationService;
}());
export { ConfirmationService };
export var useConfirmationService = function () {
    var confirmationService = inject("ConfirmationService");
    if (!confirmationService) {
        throw new Error("ConfirmationService not provided, is 'ConfirmationDialogProvider' missing in a parent component?");
    }
    return confirmationService;
};
