<template lang="pug">
.app.drive-app
  Navigation(ref="nav" :slideout="slideout")
  .content(ref="content" :class="{fullscreen: fullscreen}")
    Flash(:success="currentSuccess" :error="currentError")
    transition(:name="transitionName")
      router-view
</template>

<script>
import Navigation from "./Navigation.vue"
import { createNamespacedHelpers } from "vuex"
import Flash from "./Flash.vue"
import Slideout from "slideout"
import handleBack from "../../mixins/handleBack"

const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")

export default {
  name: "Drive",
  mixins: [handleBack],
  components: { Flash, Navigation },
  data() {
    return {
      currentSuccess: undefined,
      currentError: undefined,
      slideout: null,
      transitionName: "",
    }
  },
  watch: {
    async $route(to, from) {
      const id = to.params.inode_id

      const toDepth = to.path.split("/").length
      const fromDepth = from.path.split("/").length
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left"

      this.messagesTick()
      if (id) {
        await this.setCurrentInode(id)
      }
    },
  },
  async created() {
    if (this.$route.params.inode_id) {
      await this.setCurrentInode(this.$route.params.inode_id)
      this.messagesTick()
    }
  },
  mounted() {
    console.log(this.$refs.content, this.$refs.nav.$el)
    this.slideout = new Slideout({
      panel: this.$refs.content,
      menu: this.$refs.nav.$el,
      padding: 300,
      tolerance: 70,
      touch: true,
      grabWidth: 50,
      itemToMove: "menu",
    })
    var slideout = this.slideout
    this.$parent.$el
      .getRootNode()
      .getElementById("hamburger-menu")
      .addEventListener("click", function () {
        slideout.toggle()
      })
  },
  beforeDestroy() {
    this.$parent.$el
      .getRootNode()
      .getElementById("hamburger-menu")
      .removeEventListener("click", function () {
        slideout.toggle()
      })
  },
  methods: {
    messagesTick() {
      this.currentSuccess = this.success
      this.currentError = this.error
      this.setSuccess(false)
      this.setError(false)
    },
    ...mapMutations(["setSuccess", "setError"]),
    ...mapActions(["setCurrentInode", "loadInode"]),
  },
  computed: {
    fullscreen() {
      return this.$route.meta.fullscreen
    },
    ...mapState(["error", "success"]),
  },
}
</script>

<style>
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .app.drive-app .content {
    max-width: unset !important;
  }
}
</style>

<style scoped>
@media screen and (min-width: 1200px) and (max-width: 1333px) {
  .app.drive-app {
    flex-wrap: nowrap !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.child-view {
  position: absolute;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
