import Vue from "vue";
import { mdiBlockHelper } from "@mdi/js";
export default Vue.extend({
    name: "ItemIconContent",
    props: {
        icon: { type: String, required: true },
        text: { type: String, required: true },
        blocked: { type: Boolean, default: false },
    },
    setup: function () {
        return {
            mdiBlockHelper: mdiBlockHelper,
        };
    },
});
