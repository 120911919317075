import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import CustomAttributesUpdateErrors from "app/modules/custom-attributes/CustomAttributesUpdateErrors.vue";
import CustomAttributeValueUpdater from "features/custom_attributes/CustomAttributeValueUpdater.vue";
import "suus-api";
import { mdiClose, mdiDelete, mdiDotsVertical, mdiDrag, mdiNoteText, mdiPen, } from "@mdi/js";
import CustomAttributesEditDialog from "app/modules/custom-attributes/CustomAttributesEditDialog.vue";
import { useConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
import { useIsMobile } from "features/common/vuetify/useVuetify";
import "app/modules/custom-attributes/types";
import PopupMenu from "components/UI/PopupMenu.vue";
export default defineComponent({
    name: "CustomAttributesListItem",
    components: {
        PopupMenu: PopupMenu,
        CustomAttributesEditDialog: CustomAttributesEditDialog,
        CustomAttributeValueUpdater: CustomAttributeValueUpdater,
        CustomAttributesUpdateErrors: CustomAttributesUpdateErrors,
    },
    props: {
        customAttribute: {
            type: Object,
        },
        update: {
            type: Function,
            required: true,
        },
        batchUpdate: {
            type: Function,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var editOpen = ref(false);
        var openEdit = function () {
            editOpen.value = true;
        };
        var updaterOpen = ref(false);
        var openUpdater = function () {
            updaterOpen.value = true;
        };
        var confirmationService = useConfirmationService();
        var deleteCustomAttribute = function () { return __awaiter(_this, void 0, void 0, function () {
            var confirmed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, confirmationService.open({
                            title: "Möchtest du dieses Feld wirklich löschen?",
                            text: "Wenn du dieses Feld löschst, kann es nicht wiederhergestellt werden.",
                            confirmText: "Löschen",
                            cancelText: "Abbrechen",
                        })];
                    case 1:
                        confirmed = _a.sent();
                        if (confirmed) {
                            emit("delete", props.customAttribute.id);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var isMobile = useIsMobile();
        var dialOpen = ref(false);
        var menuItems = [
            {
                key: "edit",
                title: "Bearbeiten",
                icon: mdiPen,
                clickHandler: openEdit,
            },
            {
                key: "update",
                title: "Werte-Import",
                icon: mdiNoteText,
                clickHandler: openUpdater,
            },
            {
                key: "delete",
                title: "Löschen",
                icon: mdiDelete,
                clickHandler: deleteCustomAttribute,
            },
        ];
        return {
            mdiDrag: mdiDrag,
            mdiPen: mdiPen,
            mdiClose: mdiClose,
            mdiNoteText: mdiNoteText,
            mdiDelete: mdiDelete,
            mdiDotsVertical: mdiDotsVertical,
            editOpen: editOpen,
            updaterOpen: updaterOpen,
            menuItems: menuItems,
            isMobile: isMobile,
            dialOpen: dialOpen,
        };
    },
});
